export const AUTH_INIT = "AUTH_INIT";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const SET_ORGANIZATION_DATA = "SET_ORGANIZATION_DATA";
export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";

export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const SET_MONTH = "SET_MONTH";
